import React, { useState } from 'react'
import AddBankPopup from './AddBankPopup';
import EditBankPopup from './EditBankPopup';

const WithdrawBankDetails = ({ userBank , setUserBank }) => {
    const [showAddBankPopup , setShowAddBankPopup] = useState(false);
    const [showEditBankPopup , setShowEditBankPopup] = useState(false);

    

    return (
        <div className='mt-6'>
            <p className='heading-sm'>
                Payment will receive in
            </p>
            {
                userBank 
                ? 
                    <div className='shadow-bg p-4 mt-2 flex items-center justify-between'>
                        <div className='flex items-center gap-4'>
                            <i className="uil uil-university sm:text-5xl text-2xl gradient-text"></i>
                            <div className='sm:text-base text-[13px]'>
                                <p>
                                    {userBank?.bankName}
                                </p>
                                <p>
                                    {userBank?.accountNo}
                                </p>
                                <p>
                                    {userBank?.accountHolder}
                                </p>
                            </div>
                        </div>
                        <button 
                        onClick={() => setShowEditBankPopup(true)}
                        className='sm:text-2xl text-lg bg-primary sm:w-[50px] w-[35px] sm:h-[50px] h-[35px] rounded-full flex items-center justify-center text-pure'>
                            <i className="uil uil-pen"></i>
                        </button>
                    </div>
                : 
                    <div className='shadow-bg px-4 py-6 mt-2 flex flex-col items-center justify-center gap-4'>
                        <div className='text-red-500'>
                            You've not binded your account yet. Please bind your account to withdraw your amount.
                        </div>
                        <button 
                        className="btn-primary py-2 px-6"
                        onClick={() => setShowAddBankPopup(true)}
                        >
                            Add Account
                        </button>
                    </div>
            }

            {
                showAddBankPopup && !userBank && <AddBankPopup 
                showPopup={showAddBankPopup}
                setShowPopup={setShowAddBankPopup}
                setUserBank={setUserBank}
                />
            }
            {
                showEditBankPopup && <EditBankPopup
                showPopup={showEditBankPopup}
                setShowPopup={setShowEditBankPopup}
                userBank={userBank}
                setUserBank={setUserBank}
                />
            }
        </div>
    )
}

export default WithdrawBankDetails