import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useClickOutside from 'utils/clickOutside';

const WalletMenu = () => { 
    const walletDropMenuRef = useRef();
    const navigate = useNavigate();
    const [showDropMenu , setShowDropMenu] = useState(false);

    useClickOutside(walletDropMenuRef , () => setShowDropMenu(false))

    return (
        <div className='relative'>
            <div className='cursor-pointer' onClick={() => setShowDropMenu(!showDropMenu)}>
                <i className="uil uil-wallet icon-sm"></i>
            </div>
            {
                showDropMenu &&
                <div
                    ref={walletDropMenuRef}
                    className='absolute top-full right-0 w-[200px] py-2 rounded-md bg-pure shadow-bg text-black flex flex-col text-left sm:text-[15px] text-sm'>
                    <div
                        className='hover:bg-gray-200 py-3 px-4 cursor-pointer'
                        onClick={() => navigate('/wallet')}
                    >
                        My Wallet
                    </div>
                    <div
                        className='hover:bg-gray-200 py-3 px-4 cursor-pointer'
                        onClick={() => navigate('/withdraw-history')}
                    >
                        Withdraw History
                    </div>
                </div>
            }
        </div>
    )
}

export default WalletMenu