import footerLinks from 'data/footerLinks'
import React from 'react'
import { Link } from 'react-router-dom'

const FooterLinks = () => {
    return (
        <div>
            <div className='container mx-auto sm:px-0 px-4'>
                <div className='flex-1 w-full x-space'>
                    <h2 className="sm:text-2xl text-xl  font-semibold text-gray-600">
                        Car Rentals In India
                    </h2>
                </div>
                <div className="mt-6 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4 md:text-[15px] text-sm">
                    {
                        footerLinks?.map(item => (
                            <Link to={item?.path} key={item.id} className='hover:underline hover:text-blue-500  text-gray-600'>
                                {item.name}
                            </Link>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default FooterLinks