import LogoSvg from 'assets/svgs/LogoSvg';
import React, { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './nav.css';
import HamburgerSvg from 'assets/svgs/HamburgerSvg';
import Sidebar from '../sidebar';
import { useDrawerContext } from 'context/DrawerContext';
import { useAuthPopupContext } from 'context/AuthPopupContext';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/actions/authActions';
import useClickOutside from 'utils/clickOutside';
import BecomeHostBtn from '../BecomeHostBtn';
import WalletMenu from './WalletMenu';

const Navbar = () => {
    const userDropMenuRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setShowDrawer } = useDrawerContext();
    const { setShowAuthPopup , setCurrentStep } = useAuthPopupContext();
    const [showUserDropMenu , setShowUserDropMenu] = useState(false);
    const { user } = useSelector(state => state.auth);

    const location = useLocation();

    const isActive = (path , home) => {
        if (home) return location.pathname === '/' ;
        return location.pathname.split('/').includes(path);
    }

    const logoutHandler = () => {
        dispatch(logout(navigate , setShowAuthPopup))
    }

    useClickOutside(userDropMenuRef , () => setShowUserDropMenu(false))

    return (
        <nav className='py-4 bg-primary sm:px-12 px-4 text-center text-pure h-[70px] flex items-center fixed top-0 left-0 w-full z-20'>
            <div className='relative w-full flex items-center'>
                <div className='container relative mx-auto flex items-center justify-between '>
                    <Link to='/'>
                        <LogoSvg />
                    </Link>
                    <ul className='nav-list'>
                        <li 
                        className={`${isActive('' , true) ? 'active' : ''} nav-list-item`}
                        >
                            <Link to='/'>
                                Home
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('vehicles') ? 'active' : ''} nav-list-item`}
                        >
                            <Link to='/vehicles'>
                                Vehicles
                            </Link>
                        </li>
                        {
                            user && 
                            <li 
                            className={`${isActive('bookings') ? 'active' : ''} nav-list-item`}
                            >
                                <Link to='/bookings'>
                                    Bookings
                                </Link>
                            </li>
                        }
                        {
                            user && 
                            <li 
                            className={`${isActive('kyc') ? 'active' : ''} nav-list-item`}
                            >
                                <Link to={`${user?.kyc?.isVerified ? '/kyc/details' : '/kyc/step1'}`}>
                                    Kyc
                                </Link>
                            </li>
                        }
                        <li 
                        className={`${isActive('blogs') ? 'active' : ''} nav-list-item`}
                        >
                            <Link to='/blogs'>
                                Blogs
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('about') ? 'active' : ''} nav-list-item`}
                        >
                            <Link to='/about'>
                                About us
                            </Link>
                        </li>
                        
                        <li 
                        className={`${isActive('contact') ? 'active' : ''} nav-list-item`}
                        >
                            <Link to='/contact'>
                                Contact us
                            </Link>
                        </li>
                        
                    </ul>
                    <div className='flex items-center md:gap-8 sm:gap-4 gap-2'>
                        {
                            user && 
                            <>
                            <div className='flex items-center lg:gap-7 sm:gap-6 gap-4 relative'>
                                <WalletMenu />
                                <div className='relative'>
                                    <div className='cursor-pointer' onClick={() => setShowUserDropMenu(!showUserDropMenu)}>
                                        <i className="uil uil-user icon-sm"></i>
                                    </div>
                                    {
                                        showUserDropMenu && 
                                        <div 
                                        ref={userDropMenuRef}
                                        className='absolute top-full right-0 w-[200px] py-2 rounded-md bg-pure shadow-bg text-black flex flex-col text-left sm:text-[15px] text-sm'>
                                            <div 
                                            className='hover:bg-gray-200 py-3 px-4 cursor-pointer'
                                            onClick={() => navigate('/profile')}
                                            >
                                                My Profile
                                            </div>
                                            <div 
                                            className='hover:bg-gray-200 py-3 px-4 cursor-pointer'
                                            onClick={() => navigate('/update-password')}
                                            >
                                                Change Password
                                            </div>
                                            <div 
                                            className='hover:bg-gray-200 py-3 px-4 cursor-pointer sm:hidden block'
                                            onClick={logoutHandler}
                                            >
                                                Logout
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='h-6 w-0.5 bg-pure sm:block hidden'></div>
                            </>
                        }
                        
                        <div className='flex items-center sm:gap-8 gap-4'>
                            {
                                user 
                                ? 
                                    <button onClick={logoutHandler} className='sm:block hidden'>
                                        Logout
                                    </button>
                                :
                                    <>
                                        <button onClick={() => {
                                            setCurrentStep('login');
                                            setShowAuthPopup(true)
                                        }}>
                                            Login
                                        </button>
                                        <button onClick={() => {
                                            setCurrentStep('register');
                                            setShowAuthPopup(true)
                                        }}>
                                            Register
                                        </button>
                                    </>
                            }
                        </div>
                        <div className='lg:block hidden'>
                            <BecomeHostBtn />
                        </div>


                        <div 
                        className='lg:hidden block cursor-pointer'
                        onClick={(prev) => setShowDrawer(true)}
                        >
                            <HamburgerSvg />
                        </div>
                    </div>

                </div>

                <div>
                    <Sidebar />
                </div>
            </div>
            
        </nav>
    )
}

export default Navbar