import Input from 'components/global/Input'
import Axios from 'config/api';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { calculateBooking } from 'redux/actions/bookingActions';
import calculateCODAmountToPayOnline from 'utils/calculateCODAmoutToPayOnline';
import calculateAmountToPayOnline from 'utils/calculateCODAmoutToPayOnline';
import toastError from 'utils/toastError';

const ApplyCoupon = ({ setDiscountDetails , discountDetails , bookingAmount , setBookingData , bookingData , settings }) => {
    const coupon = JSON.parse(localStorage.getItem('discountDetails'))?.coupon?.code

    const { id } = useParams();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const [couponCode , setCouponCode] = useState(coupon || '');
    const [loading , setLoading] = useState(false);


    const submitHandler = async e => {
        e.preventDefault();
        const body = { couponCode , bookingAmount }
        try {
            setLoading(true);
            const { data : { data : { message , isValid , coupon } } } = await Axios.post('/coupon/apply' , body , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            toast.success(message);

            const discountData = {
                coupon ,
                isCouponApplied : isValid , 
            };

            dispatch(calculateBooking({
                bookingData ,
                setBookingData ,
                discountDetails : discountData ,
                setDiscountDetails ,
                carId : id 
            }))

            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error)
        }
    }

    const removeCouponHandler = () => {
        const discountData = {
            coupon : '' ,
            actualAmount : 0 ,
            discountAmount : 0 ,
            isCouponApplied : false ,
            discount : 0
        };
        dispatch(calculateBooking({
            bookingData ,
            setBookingData ,
            discountDetails : discountData ,
            setDiscountDetails ,
            carId : id ,
            setCouponCode 
        }))
    }

    return (
        <div>
            <form 
            className='flex flex-col gap-2'
            onSubmit={submitHandler}
            >
                <input 
                type="text" 
                placeholder='Apply Coupon'
                className='input'
                disabled={!bookingData?.pickupDate || !bookingData?.returnDate || discountDetails?.isCouponApplied }
                onChange={e => setCouponCode(e.target.value)}
                value={couponCode}
                required
                />
                {
                    discountDetails?.isCouponApplied 
                    ? 
                        <>
                            <button 
                            className="bg-red-500 text-white rounded-md hover:bg-red-600 py-2 px-4 disabled:cursor-not-allowed"
                            type='button'
                            onClick={removeCouponHandler}
                            >
                                remove or change coupon
                            </button>
                        </>
                    : 
                        <button 
                        className="bg-green-500 text-white rounded-md hover:bg-green-600 py-2 px-4 disabled:cursor-not-allowed"
                        disabled={!bookingData?.pickupDate || !bookingData?.returnDate || loading }            
                        type='submit'
                        >
                            {
                                loading 
                                ? 
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Apply'   
                            }
                        </button>   

                }
            </form>       
        </div>
    )
}

export default ApplyCoupon