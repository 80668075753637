const footerLinks = [
    { id: 1, path: "/slug/self-drive-cars-in-pune", name: "Self Drive Cars in Pune" },
    { id: 2, path: "/slug/self-drive-cars-in-pondicherry", name: "Self Drive Cars in Pondicherry" },
    { id: 3, path: "/slug/self-drive-cars-in-tirupati", name: "Self Drive Cars in Tirupati" },
    { id: 4, path: "/slug/self-drive-cars-in-rameshwaram", name: "Self Drive Cars in Rameshwaram" },
    { id: 5, path: "/slug/self-drive-cars-in-mahabalipuram", name: "Self Drive Cars in Mahabalipuram" },
    { id: 6, path: "/slug/self-drive-cars-in-goa", name: "Self Drive Cars in Goa" },
    { id: 7, path: "/slug/self-drive-cars-in-mumbai", name: "Self Drive Cars in Mumbai" },
    { id: 8, path: "/slug/self-drive-cars-in-indore", name: "Self Drive Cars in Indore" },
    { id: 9, path: "/slug/self-drive-cars-in-kolkata", name: "Self Drive Cars in Kolkata" },
    { id: 10, path: "/slug/self-drive-cars-in-jaipur", name: "Self Drive Cars in Jaipur" },
    { id: 11, path: "/slug/self-drive-cars-in-guwahati", name: "Self Drive Cars in Guwahati" },
    { id: 12, path: "/slug/self-drive-cars-in-chennai", name: "Self Drive Cars in Chennai" },
    { id: 13, path: "/slug/self-drive-cars-in-bangalore", name: "Self Drive Cars in Bangalore" },
    { id: 14, path: "/slug/self-drive-cars-in-hydrabad", name: "Self Drive Cars in Hydrabad" },
    { id: 15, path: "/slug/self-drive-cars-in-mysore", name: "Self Drive Cars in Mysore" },
    { id: 16, path: "/slug/self-drive-cars-in-darjeeling", name: "Self Drive Cars in Darjeeling" },
    { id: 17, path: "/slug/self-drive-cars-in-nagpur", name: "Self Drive Cars in Nagpur" },
    { id: 18, path: "/slug/self-drive-cars-in-ayodhya", name: "Self Drive Cars in Ayodhya" },
    { id: 19, path: "/slug/self-drive-cars-in-prayagraj", name: "Self Drive Cars in Prayagraj" },
    { id: 20, path: "/slug/self-drive-cars-in-gurugram", name: "Self Drive Cars in Gurugram" },
    { id: 21, path: "/slug/self-drive-cars-in-lucknow", name: "Self Drive Cars in Lucknow" },
    { id: 22, path: "/slug/self-drive-cars-in-noida", name: "Self Drive Cars in Noida" },
    { id: 23, path: "/slug/self-drive-cars-in-shimla", name: "Self Drive Cars in Shimla" },
    { id: 24, path: "/slug/self-drive-cars-in-kullu", name: "Self Drive Cars in Kullu" },
    { id: 25, path: "/slug/self-drive-cars-in-manali", name: "Self Drive Cars in Manali" },
    { id: 26, path: "/slug/self-drive-cars-in-srinagar", name: "Self Drive Cars in Srinagar" },
    { id: 27, path: "/slug/self-drive-cars-in-amritsar", name: "Self Drive Cars in Amritsar" },
    { id: 28, path: "/slug/self-drive-cars-in-chandigarh", name: "Self Drive Cars in Chandigarh" },
    { id: 29, path: "/slug/self-drive-cars-in-siliguri", name: "Self Drive Cars in Siliguri" },
    { id: 30, path: "/slug/self-drive-cars-in-vishakapatnam", name: "Self Drive Cars in Vishakapatnam" },
    { id: 31, path: "/slug/self-drive-cars-in-thiruvananthapuram", name: "Self Drive Cars in Thiruvananthapuram" },
    { id: 32, path: "/slug/self-drive-cars-in-kochi", name: "Self Drive Cars in Kochi" },
    { id: 33, path: "/slug/self-drive-cars-in-udaipur", name: "Self Drive Cars in Udaipur" },
    { id: 34, path: "/slug/self-drive-cars-in-jabalpur", name: "Self Drive Cars in Jabalpur" },
    { id: 35, path: "/slug/self-drive-cars-in-bhopal", name: "Self Drive Cars in Bhopal" },
    { id: 36, path: "/slug/self-drive-cars-in-varanasi", name: "Self Drive Cars in Varanasi" },
    { id: 37, path: "/slug/self-drive-cars-in-jammu", name: "Self Drive Cars in Jammu" },
    { id: 38, path: "/slug/self-drive-cars-in-agra", name: "Self Drive Cars in Agra" }
];
  

export default footerLinks;