const slugs = [
    "/self-drive-cars-in-pune",
    "/self-drive-cars-in-pondicherry",
    "/self-drive-cars-in-tirupati",
    "/self-drive-cars-in-rameshwaram",
    "/self-drive-cars-in-mahabalipuram",
    "/self-drive-cars-in-goa",
    "/self-drive-cars-in-mumbai",
    "/self-drive-cars-in-indore",
    "/self-drive-cars-in-kolkata",
    "/self-drive-cars-in-jaipur",
    "/self-drive-cars-in-guwahati",
    "/self-drive-cars-in-chennai",
    "/self-drive-cars-in-bangalore",
    "/self-drive-cars-in-hydrabad",
    "/self-drive-cars-in-mysore",
    "/self-drive-cars-in-darjeeling",
    "/self-drive-cars-in-nagpur",
    "/self-drive-cars-in-ayodhya",
    "/self-drive-cars-in-prayagraj",
    "/self-drive-cars-in-gurugram",
    "/self-drive-cars-in-lucknow",
    "/self-drive-cars-in-noida",
    "/self-drive-cars-in-shimla",
    "/self-drive-cars-in-kullu",
    "/self-drive-cars-in-manali",
    "/self-drive-cars-in-srinagar",
    "/self-drive-cars-in-amritsar",
    "/self-drive-cars-in-chandigarh",
    "/self-drive-cars-in-siliguri",
    "/self-drive-cars-in-vishakapatnam",
    "/self-drive-cars-in-thiruvananthapuram",
    "/self-drive-cars-in-salugara-siliguri",
    "/self-drive-cars-in-devidanga-siliguri",
    "/self-drive-cars-in-uttar-palash-siliguri",
    "/self-drive-cars-in-rupan-chhat-siliguri",
    "/self-drive-cars-in-kalkut-siliguri",
    "/self-drive-cars-in-chayanpara-siliguri",
    "/self-drive-cars-in-binkim-nagar-siliguri",
    "/self-drive-cars-in-dasrathpally-siliguri",
    "/self-drive-cars-in-jyoti-nagar-siliguri",
    "/self-drive-cars-in-phase-2-siliguri",
    "/self-drive-cars-in-malaguri-siliguri",
    "/self-drive-cars-in-champasari-siliguri",
    "/self-drive-cars-in-daknikata-siliguri",
    "/self-drive-cars-in-sevoke-road-siliguri",
    "/self-drive-cars-in-mahishmari-siliguri",
    "/self-drive-cars-in-malahar-siliguri",
    "/self-drive-cars-in-sisabari-siliguri",
    "/self-drive-cars-in-dariagramer-chhat-siliguri",
    "/self-drive-cars-in-khalpara-siliguri",
    "/self-drive-cars-in-leninpur-siliguri",
    "/self-drive-cars-in-tari-siliguri",
    "/self-drive-cars-in-palpara-siliguri",
    "/self-drive-cars-in-pradhan-nagar-siliguri",
    "/self-drive-cars-in-patiramjote-siliguri",
    "/self-drive-cars-in-matigarahat-siliguri",
    "/self-drive-cars-in-bara-gharia-siliguri",
    "/self-drive-cars-in-uttorayon-twp-siliguri",
    "/self-drive-cars-in-ghokla-jote-siliguri",
    "/self-drive-cars-in-baniakhari-siliguri",
    "/self-drive-cars-in-ds-colony-siliguri",
    "/self-drive-cars-in-shanti-nagar-siliguri",
    "/self-drive-cars-in-deshbandu-para-siliguri",
    "/self-drive-cars-in-dabgram-siliguri",
    "/self-drive-cars-in-hakimpara-siliguri",
    "/self-drive-cars-in-naukaghat-siliguri",
    "/self-drive-cars-in-patiram-siliguri",
    "/self-drive-cars-in-shaktigarh-siliguri",
    "/self-drive-cars-in-milanpally-siliguri",
    "/self-drive-cars-in-tulasi-nagar-siliguri",
    "/self-drive-cars-in-ganga-nagar-siliguri",
    "/self-drive-cars-in-mahananda-para-siliguri",
    "/self-drive-cars-in-bagdogra-siliguri",
    "/self-drive-cars-in-bagdogra-international-airport-siliguri",
    "/self-drive-cars-in-sahudangi-bazar-siliguri",
    "/self-drive-cars-in-rahamu-siliguri",
    "/self-drive-cars-in-gangapani-siliguri",
    "/self-drive-cars-in-bara-pathuram-siliguri",
    "/self-drive-cars-in-bangla-basti-siliguri",
    "/self-drive-cars-in-fulbari-siliguri",
    "/self-drive-cars-in-nayapara-siliguri",
    "/self-drive-cars-in-tea-garden-siliguri",
    "/self-drive-cars-in-hatiadanga-siliguri",
    "/self-drive-cars-in-sukantapally-siliguri",
    "/self-drive-cars-in-salugara-siligur",
    "/self-drive-cars-in-bara-mohansingh-siliguri",
    "/self-drive-cars-in-bairatisal-siliguri",
    "/self-drive-cars-in-rupsing-siliguri",
    "/self-drive-cars-in-dumriguri-siliguri",
    "/self-drive-cars-in-bhujia-banir-chhat-siliguri",
    "/self-drive-cars-in-lalman-siliguri",
    "/self-drive-cars-in-sukna-siliguri",
    "/self-drive-cars-in-sahibhita-siliguri",
    "/self-drive-cars-in-turibhita-siliguri",
    "/self-drive-cars-in-don-bosco-colony-siliguri",
    "/self-drive-cars-in-kalabari-siliguri",
    "/self-drive-cars-in-sheela-nagar-vishakapatnam",
    "/self-drive-cars-in-akkireddypalem-vishakapatnam" ,
    "/self-drive-cars-in-tungalam-vishakapatnam",
    "/self-drive-cars-in-vishakapatnam-international-airport",
    "/self-drive-cars-in-gopalapatnam-vishakapatnam",
    "/self-drive-cars-in-nadupuru-vishakapatnam",
    "/self-drive-cars-in-vadlapudi-vishakapatnam",
    "/self-drive-cars-in-auto-nagar-vishakapatnam",
    "/self-drive-cars-in-dayal-nagar-vishakapatnam",
    "/self-drive-cars-in-chaitanya-nagar-vishakapatnam",
    "/self-drive-cars-in-gajuwaka-vishakapatnam",
    "/self-drive-cars-in-dr-ntr-beach-vishakapatnam",
    "/self-drive-cars-in-port-area-vishakapatnam",
    "/self-drive-cars-in-one-town-vishakapatnam",
    "/self-drive-cars-in-daspalla-hills-vishakapatnam",
    "/self-drive-cars-in-purna-market-vishakapatnam",
    "/self-drive-cars-in-jagadamba-junction-vishakapatnam",
    "/self-drive-cars-in-kancharapalem-vishakapatnam",
    "/self-drive-cars-in-mvp-colony-vishakapatnam",
    "/self-drive-cars-in-maddilapalem-vishakapatnam",
    "/self-drive-cars-in-akkayyapalem-vishakapatnam",
    "/self-drive-cars-in-dwaraka-nagar-vishakapatnam",
    "/self-drive-cars-in-seethammadara-vishakapatnam",
    "/self-drive-cars-in-vishakapatnam",
    "/self-drive-cars-in-relliveedhi-vishakapatnam",
    "/self-drive-cars-in-gokul-park-vishakapatnam",
    "/self-drive-cars-in-maharani-peta-vishakapatnam",
    "/self-drive-cars-in-nad-junction-vishakapatnam",
    "/self-drive-cars-in-vepagunta-vishakapatnam",
    "/self-drive-cars-in-krishnarayapuram-vishakapatnam",
    "/self-drive-cars-in-nagamalli-nagar-layout-vishakapatnam",
    "/self-drive-cars-in-chinnamushidiwada-vishakapatnam",
    "/self-drive-cars-in-pendurthi-vishakapatnam",
    "/self-drive-cars-in-sujatha-nagar-vishakapatnam",
    "/self-drive-cars-in-gandhi-institute-of-technology-vishakapatnam",
    "/self-drive-cars-in-sagar-nagar-vishakapatnam",
    "/self-drive-cars-in-mvv-green-field-vishakapatnam",
    "/self-drive-cars-in-mubarak-colony-vishakapatnam",
    "/self-drive-cars-in-srinavasa-nagar-vishakapatnam",
    "/self-drive-cars-in-sc-nagar-vishakapatnam",
    "/self-drive-cars-in-yendada-vishakapatnam",
    "/self-drive-cars-in-yadava-jaggaraju-nagar-vishakapatnam",
    "/self-drive-cars-in-nathayyapalem-vishakapatnam",
    "/self-drive-cars-in-mindi-vishakapatnam",
    "/self-drive-cars-in-jalaripeta-vishakapatnam",
    "/self-drive-cars-in-pedda-waltair-vishakapatnam",
    "/self-drive-cars-in-kirlampudi-layout-vishakapatnam",
    "/self-drive-cars-in-jalari-peta-vishakapatnam",
    "/self-drive-cars-in-ushodaya-junction-vishakapatnam",
    "/self-drive-cars-in-au-outgate-vishakapatnam",
    "/self-drive-cars-in-shivaji-park-vishakapatnam",
    "/self-drive-cars-in-madhavadhara-vishakapatnam",
    "/self-drive-cars-in-muralinagar-vishakapatnam",
    "/self-drive-cars-in-daba-gardens-vishakapatnam",
    "/self-drive-cars-in-srinagar-vishakapatnam",
    "/self-drive-cars-in-kalinganagar-vishakapatnam",
    "/self-drive-cars-in-industrial-estate-vishakapatnam",
    "/self-drive-cars-in-lb-nagar-vishakapatnam",
    "/self-drive-cars-in-bapuji-nagar-vishakapatnam",
    "/self-drive-cars-in-shyam-nagar-colony-vishakapatnam",
    "/self-drive-cars-in-gurrampalem-vishakapatnam",
    "/self-drive-cars-in-pulaganipalem-vishakapatnam",
    "/self-drive-cars-in-juthada-vishakapatnam",
    "/self-drive-cars-in-valimeraka-vishakapatnam",
    "/self-drive-cars-in-kavali-vishakapatnam",
    "/self-drive-cars-in-mudapaka-vishakapatnam",
    "/self-drive-cars-in-prasanthi-nagar-vishakapatnam",
    "/self-drive-cars-in-thiruvananthapuram",
    "/self-drive-cars-in-kowdiar-thiruvananthapuram",
    "/self-drive-cars-in-kuravankonam-thiruvananthapuram",
    "/self-drive-cars-in-bagavathy-nagar-thiruvananthapuram",
    "/self-drive-cars-in-swathi-nagar-thiruvananthapuram",
    "/self-drive-cars-in-pipinmoodu-thiruvananthapuram",
    "/self-drive-cars-in-devi-nagar-thiruvananthapuram",
    "/self-drive-cars-in-gandhi-smaraka-nagar-thiruvananthapuram",
    "/self-drive-cars-in-kazhakkoottam-thiruvananthapuram",
    "/self-drive-cars-in-chittattumukku-thiruvananthapuram",
    "/self-drive-cars-in-kaniyapuram-thiruvananthapuram",
    "/self-drive-cars-in-pallithura-thiruvananthapuram",
    "/self-drive-cars-in-kulathoor-thiruvananthapuram",
    "/self-drive-cars-in-alathara-thiruvananthapuram",
    "/self-drive-cars-in-karimanal-thiruvananthapuram",
    "/self-drive-cars-in-kesavadasapuram-thiruvananthapuram",
    "/self-drive-cars-in-ulloor-garden-thiruvananthapuram",
    "/self-drive-cars-in-bhasi-nagar-thiruvananthapuram",
    "/self-drive-cars-in-pananvala-thiruvananthapuram",
    "/self-drive-cars-in-jai-nagar-thiruvananthapuram",
    "/self-drive-cars-in-pattom-thiruvananthapuram",
    "/self-drive-cars-in-parottukonam-thiruvananthapuram",
    "/self-drive-cars-in-adarsh-nagar-thiruvananthapuram",
    "/self-drive-cars-in-vrindavan-gardens-thiruvananthapuram",
    "/self-drive-cars-in-gowreesapattom-thiruvananthapuram",
    "/self-drive-cars-in-murinjapalam-thiruvananthapuram",
    "/self-drive-cars-in-manjulam-thiruvananthapuram",
    "/self-drive-cars-in-marappalam-thiruvananthapuram",
    "/self-drive-cars-in-sasthamangalam-thiruvananthapuram",
    "/self-drive-cars-in-edapazhanji-thiruvananthapuram",
    "/self-drive-cars-in-vattiyoorkavu-thiruvananthapuram",
    "/self-drive-cars-in-vazhuthacaud-thiruvananthapuram",
    "/self-drive-cars-in-vivekanand-nagar-thiruvananthapuram",
    "/self-drive-cars-in-cotton-hill-thiruvananthapuram",
    "/self-drive-cars-in-akkulam-thiruvananthapuram" ,
    "/self-drive-cars-in-suvarnagiri-junction-thiruvananthapuram",
    "/self-drive-cars-in-sooryamangalam-thiruvananthapuram",
    "/self-drive-cars-in-ambadi-nagar-thiruvananthapuram",
    "/self-drive-cars-in-pongumoodu-thiruvananthapuram",
    "/self-drive-cars-in-sreekariyam-thiruvananthapuram",
    "/self-drive-cars-in-prasanth-nagar-thiruvananthapuram",
    "/self-drive-cars-in-chalakkuzhi-thiruvananthapuram",
    "/self-drive-cars-in-murinjapalayam-thiruvananthapuram",
    "/self-drive-cars-in-sat-hospital-thiruvananthapuram",
    "/self-drive-cars-in-kovalam-thiruvananthapuram",
    "/self-drive-cars-in-kovalam-beach-thiruvananthapuram",
    "/self-drive-cars-in-vazhamuttom-thiruvananthapuram",
    "/self-drive-cars-in-muttakkad-thiruvananthapuram",
    "/self-drive-cars-in-sreekariyam-thiruvananthapuram",
    "/self-drive-cars-in-pattom-thiruvananthapuram",
    "/self-drive-cars-in-vannar-street-rameshwaram",
    "/self-drive-cars-in-rameshwaram-railway-station",
    "/self-drive-cars-in-bharathi-nagar-rameshwaram",
    "/self-drive-cars-in-barathi-nagar-rameshwaram",
    "/self-drive-cars-in-p-n-nagar-rameshwaram",
    "/self-drive-cars-in-sivakami-nagar-rameshwaram",
    "/self-drive-cars-in-natrajapuram-rameshwaram",
    "/self-drive-cars-in-dhanushkodi-entry-rameshwaram",
    "/self-drive-cars-in-rameshwaram-beach",
    "/self-drive-cars-in-kalavai-st-rameshwaram",
    "/self-drive-cars-in-brahma-theertham-rameshwaram",
    "/self-drive-cars-in-aathi-kaadu-rameshwaram",
    "/self-drive-cars-in-erakadu-rameshwaram",
    "/self-drive-cars-in-s-car-st-rameshwaram",
    "/self-drive-cars-in-v-o-c-nagar-rameshwaram",
    "/self-drive-cars-in-railway-feeder-rd-rameshwaram",
    "/self-drive-car-in-rameshwaram-without-driver",
    "/self-drive-car-in-vannar-street-without-driver-rameshwaram",
    "/self-drive-car-in-rameshwaram-railway-station-without-driver",
    "/self-drive-car-in-bharathi-nagar-without-driver-rameshwaram",
    "/self-drive-car-in-barathi-nagar-without-driver-rameshwaram",
    "/self-drive-car-in-p-n-nagar-without-driver-rameshwaram",
    "/self-drive-car-in-sivakami-nagar-without-driver-rameshwaram",
    "/self-drive-car-in-natrajapuram-without-driver-rameshwaram",
    "/self-drive-car-in-dhanushkodi-entry-without-driver-rameshwaram",
    "/self-drive-car-in-v-o-c-nagar-without-driver-rameshwaram",
    "/self-drive-car-in-rameshwaram-beach-without-driver",
    "/self-drive-car-in-kalavai-st-without-driver-rameshwaram",
    "/self-drive-car-in-brahma-theertham-without-driver-rameshwaram",
    "/self-drive-car-in-aathi-kaadu-without-driver-rameshwaram",
    "/self-drive-car-in-erakadu-without-driver-rameshwaram",
    "/self-drive-car-in-railway-feeder-rd-without-driver-rameshwaram",
    "/self-drive-car-in-s-car-st-without-driver-rameshwaram",
    "/self-drive-car-rental-in-rameshwaram-rent-drive",
    "/self-drive-car-on-rent-in-vannar-street-rameshwaram",
    "/self-drive-car-on-rent-in-rameshwaram-railway-station",
    "/self-drive-car-on-rent-in-bharathi-nagar-rameshwaram",
    "/self-drive-car-on-rent-in-barathi-nagar-rameshwaram",
    "/self-drive-car-on-rent-in-p-n-nagar-rameshwaram",
    "/self-drive-car-on-rent-in-sivakami-nagar-rameshwaram",
    "/self-drive-car-on-rent-in-natrajapuram-rameshwaram",
    "/self-drive-car-on-rent-in-dhanushkodi-entry-rameshwaram",
    "/self-drive-car-on-rent-in-v-o-c-nagar-rameshwaram",
    "/self-drive-car-on-rent-in-rameshwaram-beach",
    "/self-drive-car-on-rent-in-kalavai-st-rameshwaram",
    "/self-drive-car-on-rent-in-brahma-theertham-rameshwaram",
    "/self-drive-car-on-rent-in-aathi-kaadu-rameshwaram",
    "/self-drive-car-on-rent-in-erakadu-rameshwaram",
    "/self-drive-car-on-rent-in-railway-feeder-rd-rameshwaram",
    "/self-drive-car-on-rent-in-s-car-st-rameshwaram",
    "/self-drive-cars-in-muthialpet-pondicherry",
    "/self-drive-cars-in-solai-nagar-pondicherry",
    "/self-drive-cars-in-bharathi-nagar-pondicherry",
    "/self-drive-cars-in-krishna-nagar-pondicherry",
    "/self-drive-cars-in-sodhanaikuppam-pondicherry",
    "/self-drive-cars-in-jammyath-nagar-pondicherry",
    "/self-drive-cars-in-kottamedu-pondicherry",
    "/self-drive-cars-in-mudaliarpet-pondicherry",
    "/self-drive-cars-in-maraimalai-pondicherry",
    "/self-drive-cars-in-vanarapet-pondicherry",
    "/self-drive-cars-in-velrampet-pondicherry",
    "/self-drive-cars-in-nainar-mandapam-pondicherry",
    "/self-drive-cars-in-nehru-nagar-pondicherry",
    "/self-drive-cars-in-karamanikuppam-pondicherry",
    "/self-drive-cars-in-nellithope-pondicherry",
    "/self-drive-cars-in-natesan-nagar-pondicherry",
    "/self-drive-cars-in-anna-nagar-pondicherry",
    "/self-drive-cars-in-orleanpet-pondicherry",
    "/self-drive-cars-in-saram-pondicherry",
    "/self-drive-cars-in-aruthra-nagar-pondicherry",
    "/self-drive-cars-in-raddiarpalayam-pondicherry",
    "/self-drive-cars-in-dhanvantari-nagar-pondicherry",
    "/self-drive-cars-in-kalaivanar-nagar-pondicherry",
    "/self-drive-cars-in-gorimedu-pondicherry",
    "/self-drive-cars-in-tagore-nagar-pondicherry",
    "/self-drive-cars-in-ananda-nagar-pondicherry",
    "/self-drive-cars-in-vm-garden-pondicherry",
    "/self-drive-cars-in-pakkamudayanpet-pondicherry",
    "/self-drive-cars-in-kumaran-nagar-pondicherry",
    "/self-drive-cars-in-tgs-nagar-pondicherry",
    "/self-drive-cars-in-ariyankuppam-pondicherry",
    "/self-drive-cars-in-sriram-nagar-pondicherry",
    "/self-drive-cars-in-agathiyar-nagar-pondicherry",
    "/self-drive-cars-in-srinivasa-avenue-pondicherry",
    "/self-drive-cars-in-toll-gate-pondicherry" ,
    "/self-drive-cars-in-kalaignar-nagar-pondicherry",
  "/self-drive-cars-in-shivaji-nagar-pondicherry",
  "/self-drive-cars-in-chinna-veeraampattinam-pondicherry",
  "/self-drive-cars-in-kaakaayanthope-pondicherry",
  "/self-drive-cars-in-chinna-irusampalayam-pondicherry",
  "/self-drive-cars-in-pudhukuppam-pondicherry",
  "/self-drive-cars-in-lawspet-pondicherry",
  "/self-drive-cars-in-thattanchavady-pondicherry",
  "/self-drive-cars-in-mahaveer-nagar-pondicherry",
  "/self-drive-cars-in-bandra-mumbai",
  "/self-drive-cars-in-khar-west-mumbai",
  "/self-drive-cars-in-railway-colony-mumbai",
  "/self-drive-cars-in-naupada-mumbai",
  "/self-drive-cars-in-kherwadi-mumbai",
  "/self-drive-cars-in-bandra-junction-mumbai",
  "/self-drive-cars-in-pali-hill-mumbai",
  "/self-drive-cars-in-teachers-colony-mumbai",
  "/self-drive-cars-in-maratha-colony-mumbai",
  "/self-drive-cars-in-khar-danda-mumbai",
  "/self-drive-cars-in-reclamation-mumbai",
  "/self-drive-cars-in-juhu-mumbai",
  "/self-drive-cars-in-vile-parle-mumbai",
  "/self-drive-cars-in-juhu-beach-mumbai",
  "/self-drive-cars-in-amboli-mumbai",
  "/self-drive-cars-in-chakala-mumbai",
  "/self-drive-cars-in-irla-mumbai",
  "/self-drive-cars-in-azad-nagar-mumbai",
  "/self-drive-cars-in-sher-e-panjab-colony-mumbai",
  "/self-drive-cars-in-munshi-nagar-mumbai",
  "/self-drive-cars-in-gulmohor-colony-mumbai",
  "/self-drive-cars-in-d-n-nagar-mumbai",
  "/self-drive-cars-in-sagar-city-mumbai",
  "/self-drive-cars-in-worli-mumbai",
  "/self-drive-cars-in-siddharth-nagar-mumbai",
  "/self-drive-cars-in-hanuman-nagar-mumbai",
  "/self-drive-cars-in-lower-parel-mumbai",
  "/self-drive-cars-in-koliwada-mumbai",
  "/self-drive-cars-in-worli-sea-face-mumbai",
  "/self-drive-cars-in-mumbai-central",
  "/self-drive-cars-in-tardeo-mumbai",
  "/self-drive-cars-in-breach-candy-mumbai",
  "/self-drive-cars-in-grant-road-mumbai",
  "/self-drive-cars-in-charni-road-east-mumbai",
  "/self-drive-cars-in-malabar-hill-mumbai",
  "/self-drive-cars-in-raj-bhavan-mumbai",
  "/self-drive-cars-in-walkeshwar-mumbai",
  "/self-drive-cars-in-haji-ali-mumbai",
  "/self-drive-cars-in-tulsiwadi-mumbai",
  "/self-drive-cars-in-rto-colony-mumbai",
  "/self-drive-cars-in-byculla-mumbai",
  "/self-drive-cars-in-mustafa-bazar-mumbai",
  "/self-drive-cars-in-ashadam-colony-mumbai",
  "/self-drive-cars-in-mazgaon-mumbai",
  "/self-drive-cars-in-tadwadi-mumbai",
  "/self-drive-cars-in-dockyard-mumbai",
  "/self-drive-cars-in-kamathipura-mumbai",
  "/self-drive-cars-in-lal-baug-mumbai",
  "/self-drive-cars-in-chinchpokli-mumbai",
  "/self-drive-cars-in-kala-chouki-mumbai",
  "/self-drive-cars-in-sewri-mumbai",
  "/self-drive-cars-in-tilak-nagar-mumbai",
  "/self-drive-cars-in-parel-mumbai",
  "/self-drive-cars-in-jawahar-nagar-mumbai",
  "/self-drive-cars-in-golibar-mumbai",
  "/self-drive-cars-in-bhoiwada-mumbai",
  "/self-drive-cars-in-chembur-mumbai",
  "/self-drive-cars-in-swastik-park-mumbai",
  "/self-drive-cars-in-suman-nagar-mumbai",
  "/self-drive-cars-in-jijamata-nagar-mumbai",
  "/self-drive-cars-in-navjeevan-society-mumbai",
  "/self-drive-cars-in-vasi-naka-mumbai",
  "/self-drive-cars-in-tat-colony-mumbai",
  "/self-drive-cars-in-h-p-nagar-mumbai",
  "/self-drive-cars-in-bharat-nagar-mumbai",
  "/self-drive-cars-in-vishnu-nagar-mumbai",
  "/self-drive-cars-in-sahyadri-nagar-mumbai",
  "/self-drive-cars-in-rcf-colony-mumbai",
  "/self-drive-cars-in-collector-colony-mumbai",
  "/self-drive-cars-in-ghatkopar-mumbai",
  "/self-drive-cars-in-mankur-mumbai",
  "/self-drive-cars-in-bhatwadi-mumbai",
  "/self-drive-cars-in-asalpha-mumbai",
  "/self-drive-cars-in-saki-naka-mumbai",
  "/self-drive-cars-in-jarimari-mumbai",
  "/self-drive-cars-in-pirojsha-nagar-mumbai",
  "/self-drive-cars-in-hariyali-mumbai",
  "/self-drive-cars-in-chindiwali-mumbai",
  "/self-drive-cars-in-powai-mumbai",
  "/self-drive-cars-in-tagore-nagar-mumbai",
  "/self-drive-cars-in-vikhroli-mumbai",
  "/self-drive-cars-in-raheja-vihar-mumbai",
  "/self-drive-cars-in-navi-mumbai",
  "/self-drive-cars-in-airoli-mumbai",
  "/self-drive-cars-in-vashi-mumbai",
  "/self-drive-cars-in-taloja-mumbai",
  "/self-drive-cars-in-wahal-mumbai",
  "/self-drive-cars-in-kalamboli-mumbai",
  "/self-drive-cars-in-jasai-mumbai",
  "/self-drive-cars-in-mhatwali-mumbai",
  "/self-drive-cars-in-palaspa-mumbai" ,
  "/self-drive-cars-in-karanjade-mumbai",
  "/self-drive-cars-in-shilphata-mumbai",
  "/self-drive-cars-in-palava-city-mumbai",
  "/self-drive-cars-in-mumbra-mumbai",
  "/self-drive-cars-in-thane-mumbai",
  "/self-drive-cars-in-hiranandani-estate-mumbai",
  "/self-drive-cars-in-dapode-mumbai",
  "/self-drive-cars-in-bhiwandi-mumbai",
  "/self-drive-cars-in-bhayandarpada-mumbai",
  "/self-drive-cars-in-thane-west-mumbai",
  "/self-drive-cars-in-majiwad-mumbai",
  "/self-drive-cars-in-vasant-vihar-mumbai",
  "/self-drive-cars-in-vitawa-mumbai",
  "/self-drive-cars-in-diva-mumbai",
  "/self-drive-cars-in-sabegaon-mumbai",
  "/self-drive-cars-in-khidkali-mumbai",
  "/self-drive-cars-in-agasan-mumbai",
  "/self-drive-cars-in-betwade-gaon-mumbai",
  "/self-drive-cars-in-dombivli-mumbai",
  "/self-drive-cars-in-sonarpada-mumbai",
  "/self-drive-cars-in-thakurli-mumbai",
  "/self-drive-cars-in-kalyan-east-mumbai",
  "/self-drive-cars-in-mothagaon-mumbai",
  "/self-drive-cars-in-dombivli-east-mumbai",
  "/self-drive-cars-in-khadakpada-mumbai",
  "/self-drive-cars-in-shahad-mumbai",
  "/self-drive-cars-in-vitthalwadi-mumbai",
  "/self-drive-cars-in-chinchpada-gaon-mumbai",
  "/self-drive-cars-in-a-wholwadi-pune",
  "/self-drive-cars-in-aga-khan-palace-pune",
  "/self-drive-cars-in-amanora-mall-pune",
  "/self-drive-cars-in-amanora-township-pune",
  "/self-drive-cars-in-aundh-pune",
  "/self-drive-cars-in-badhir-park-pune",
  "/self-drive-cars-in-balewadi-pune",
  "/self-drive-cars-in-baner-pune",
  "/self-drive-cars-in-bhekrainagor-pune",
  "/self-drive-cars-in-bolewadi-pune",
  "/self-drive-cars-in-bund-garden-pune",
  "/self-drive-cars-in-dhanori-pune",
  "/self-drive-cars-in-evershine-nagar-pune",
  "/self-drive-cars-in-g-mall-pune",
  "/self-drive-cars-in-govt-college-pune",
  "/self-drive-cars-in-hadapsar-pune",
  "/self-drive-cars-in-hanuman-nagar-pune",
  "/self-drive-cars-in-hinjewadi-pune",
  "/self-drive-cars-in-kalyani-nagar-pune",
  "/self-drive-cars-in-katroj-to-dehu-road-pune",
  "/self-drive-cars-in-keshav-nagar-pune",
  "/self-drive-cars-in-kharadi-pune",
  "/self-drive-cars-in-kondhwa-khurd-pune",
  "/self-drive-cars-in-kondhwa-road-pune",
  "/self-drive-cars-in-koregaon-park-pune",
  "/self-drive-cars-in-lohegaon-pune",
  "/self-drive-cars-in-lonkar-nagar-pune",
  "/self-drive-cars-in-mahalaxmi-kids-garden-pune",
  "/self-drive-cars-in-manjari-pune",
  "/self-drive-cars-in-mith-chowky-malad-pune",
  "/self-drive-cars-in-mundhawa-pune",
  "/self-drive-cars-in-mundhwa-pune",
  "/self-drive-cars-in-nagar-road-pune",
  "/self-drive-cars-in-nibm-road-pune",
  "/self-drive-cars-in-noble-hospital-pune",
  "/self-drive-cars-in-pashan-pune",
  "/self-drive-cars-in-phoenix-market-city-pune",
  "/self-drive-cars-in-pimple-saudagar-pune",
  "/self-drive-cars-in-pune-airport",
  "/self-drive-cars-in-pune-anand-nagar",
  "/self-drive-cars-in-pune-krushnaji-khanduji-ghule-vidyalaya",
  "/self-drive-cars-in-pune-kunjir-public-school",
  "/self-drive-cars-in-pune-magarpatta",
  "/self-drive-cars-in-pune-mumbai-expressway",
  "/self-drive-cars-in-pune-okayama-friendship-garden",
  "/self-drive-cars-in-pune-renuka-mata-mandir",
  "/self-drive-cars-in-pune-sasane-colony",
  "/self-drive-cars-in-pune-shankar-nagar",
  "/self-drive-cars-in-pune-station",
  "/self-drive-cars-in-railway-station-pune",
  "/self-drive-cars-in-s-b-road-pune",
  "/self-drive-cars-in-sai-baba-park-pune",
  "/self-drive-cars-in-saras-baug-pune",
  "/self-drive-cars-in-sasun-hospital-pune",
  "/self-drive-cars-in-savitribai-phule-university-pune",
  "/self-drive-cars-in-seasons-mall-pune",
  "/self-drive-cars-in-shaniwar-wada-pune",
  "/self-drive-cars-in-shivaji-nagar-pune",
  "/self-drive-cars-in-tingare-nagar-pune",
  "/self-drive-cars-in-viman-nagar-pune",
  "/self-drive-cars-in-vishal-nagar-pune",
  "/self-drive-cars-in-wadgaon-sheri-pune",
  "/self-drive-cars-in-wagholi-pune",
  "/self-drive-cars-in-wakad-pune",
  "/self-drive-cars-in-z-corner-pune",
  "/self-drive-cars-in-tiruchanur-tirupati",
  "/self-drive-cars-in-vasundhara-nagar-tirupati",
  "/self-drive-cars-in-dainedu-tirupati",
  "/self-drive-cars-in-korramenugunta-tirupati",
  "/self-drive-cars-in-tirupati-bypass-road",
  "/self-drive-cars-in-mangalam-tirupati",
  "/self-drive-cars-in-btr-colony-tirupati",
  "/self-drive-cars-in-settipalli-tirupati",
  "/self-drive-cars-in-renigunta-tirupati",
  "/self-drive-cars-in-venkatapuram-tirupati",
  "/self-drive-cars-in-auto-nagar-tirupati",
  "/self-drive-cars-in-chengapalli-tirupati",
  "/self-drive-cars-in-kothapalem-tirupati",
  "/self-drive-cars-in-elamandyam-tirupati",
  "/self-drive-cars-in-chennayyagunta-tirupati",
  "/self-drive-cars-in-pennerukalva-tirupati",
  "/self-drive-cars-in-pudipatla-tirupati",
  "/self-drive-cars-in-cherlopalli-tirupati",
  "/self-drive-cars-in-thondavada-tirupati",
  "/self-drive-cars-in-shree-venkateshwara-nagar-tirupati",
  "/self-drive-cars-in-chandamama-palli-tirupati",
  "/self-drive-cars-in-kaalur-tirupati",
  "/self-drive-cars-in-mallavaram-tirupati"
];

  
export default slugs