import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import FormControl from 'components/form/FormControl'
import WithdrawBankDetails from 'components/wallet/WithdrawBankDetails'
import Axios from 'config/api'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import fetcher from 'utils/fetcher'
import formatAmount from 'utils/formatAmount';
import toastError from 'utils/toastError'
import * as Yup from 'yup';
import { setUser } from 'redux/reducers/authReducer'



const initState = {
    withdrawAmount : ''
}

const Wallet = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);

    const [settings , setSettings] = useState(null);
    const [userBank , setUserBank] = useState(null);
    const [loading , setLoading] = useState(false);

    const validations = Yup.object().shape({
        withdrawAmount : Yup.number()
            .required('Withdrawal amount is required')
            .when([], {
                is: () => settings && settings.minWithdraw,
                then: (schema) => schema.min(settings?.minWithdraw, `Minimum withdraw amount is ${settings?.minWithdraw}`),
            })
            .max(user?.wallet?.totalBalance , `Invalid amount. Your current balance is ${user?.wallet?.totalBalance}`)
            .positive('Withdrawal amount must be a positive number')
    });

    const queryKey = 'fetch-settings-with-bank';
    const { isLoading , data } = useQuery(queryKey, () => {
        return fetcher('/setting/bank', user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { settings , bank , wallet } } } = data;
            setSettings(settings);
            setUserBank(bank);
            const updatedUser = {...user , wallet };
            dispatch(setUser(updatedUser));
            localStorage.setItem('user' , JSON.stringify(updatedUser));
        }
    } , [data]);

    const handleSubmit = async (formData) => {
        setLoading(true);
        try {
            const { data : { data : { message } } }  = await Axios.post('/withdraw' , formData , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            toast.success(message);
            setLoading(false);
            navigate('/withdrawals')
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    return (
        <div className='container mx-auto x-space px-4 pt-6 pb-20 relative'>
            <div className="flex items-center justify-between">
                <Heading title='Your Wallet' showIcon={false} />
                <BackBtn />
            </div>
            {
                isLoading
                ? 
                    <Loader />
                : 
                    <>
                        <div className='mt-6 shadow-bg p-4 sm:py-8 rounded-lg'>
                            <div className='flex items-center justify-center flex-col gap-1'>
                                <h3 className='text-lg'>Available Balance</h3>
                                <p className='sm:text-3xl text-primary font-semibold gradient-text text-xl'>INR:{formatAmount(user?.wallet?.totalBalance)}</p>
                            </div>
                        </div>
                        <div>
                            <WithdrawBankDetails 
                            userBank={userBank} 
                            setUserBank={setUserBank}
                            />
                        </div>
                        {
                            userBank && (
                                <div className='shadow-bg p-4 mt-6'>
                                    <Formik
                                    initialValues={initState}
                                    validationSchema={validations}
                                    onSubmit={handleSubmit}
                                    
                                    >
                                        {
                                            formik => {
                                                return (
                                                    <Form>
                                                        <FormControl
                                                            type='number'
                                                            control='input'
                                                            label='Withdraw Amount'
                                                            placeholder='Enter withdraw amount'
                                                            name='withdrawAmount'
                                                            formik={formik}
                                                        />
                                                        <button 
                                                        className="btn-primary py-2 sm:px-6 px-4 block mt-4"
                                                        type='submit'
                                                        disabled={loading || !formik.isValid}
                                                        >
                                                            {
                                                                loading 
                                                                ? 
                                                                    <ClipLoader size={20} color='white' />
                                                                : 
                                                                    'Submit'
                                                            }
                                                        </button>
                                                    </Form>
                                                )
                                            }
                                        }
                                    </Formik>
                                </div>
                            )
                        }
                    </>
            }
        </div>
    )
}

export default Wallet