import Faq from 'components/home/Faqs'
import GetInTouch from 'components/home/GetInTouch'
import Hero from 'components/home/Hero'
import HowItWorks from 'components/home/HowItWorks'
import PageContent from 'components/home/PageContent'
import PopularCars from 'components/home/PopularCars'
import Testimonials from 'components/home/Testimonials'
import WhyChooseUs from 'components/home/WhyChooseUs'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import { setHomePageContent, setPopularCars, setSettings, setSlugData } from 'redux/reducers/homeReducer'
import fetcher from 'utils/fetcher';
import React from 'react'
import FooterLinks from 'components/home/FooterLinks'

const Home = () => {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const { slugData } = useSelector(state => state.home)

    const { isLoading, data } = useQuery(`fetch-home-data`, () => {
        return fetcher(`/home?slug=${slug}`)
    },
        {
            refetchOnWindowFocus: false
        }
    );

    useEffect(() => {
        if (data) {
            const { data: { data: { popularCars, settings, homePageContent, slugData } } } = data;
            dispatch(setPopularCars(popularCars));
            dispatch(setSettings(settings));
            dispatch(setHomePageContent(homePageContent));
            dispatch(setSlugData(slugData))
        }
    }, [data]);

    const descriptionTagExists = slugData?.metaTags?.some(tag => tag?.name === 'description');

    return (
        <div>
            {
                slugData
                ?
                    <Helmet>
                        {slugData?.pageTitle && (
                            <title>{slugData.pageTitle.replace(/-/g, ' ')}</title>
                        )}
                        {
                            slugData.metaTags?.length > 0 && slugData.metaTags.map((tag, index) => {
                                if (tag.type === 'og') {
                                    return <meta key={index} property={tag.property} content={tag.content} />;
                                } else if (tag.type === 'canonical') {
                                    return <link key={index} rel={tag.rel} href={tag.href} />;
                                } else {
                                    return <meta key={index} name={tag.name} content={tag.content} />;
                                }
                            })
                        }
                    </Helmet>
                :
                    <Helmet>
                        <title>Rentndrive - Cheap Self drive Car Rental in India with Driver</title>
                        <meta name="description" content="Rentndrive India's no. 1 Self drive car rental services. We offer with or without driver. Book at Affordable rates, flexible options, and hassle-free booking." />
                        <meta name="robots" content="Index, Follow" />

                        <meta property="og:title" content="Cheap Self drive Car Rental in India with Driver" />
                        <meta property="og:description" content="Rentndrive India's no. 1 Self drive car rental services. We offer with or without driver. Book at Affordable rates, flexible options, and hassle-free booking." />

                        <meta property="og:url" content="https://rentndrive.co.in" />
                        <meta property="og:type" content="website" />
                        <meta property="og:site_name" content="Rentndrive" />
                        <meta name="twitter:card" content="Rentndrive" />
                        <meta name="twitter:site" content="Rentndrive" />
                        <meta name="twitter:title" content="Cheap Self drive Car Rental in India with Driver" />
                        <meta name="twitter:description" content="Rentndrive India's no. 1 Self drive car rental services. We offer with or without driver. Book at Affordable rates, flexible options, and hassle-free booking." />
                        <meta property="twitter:url" content="https://rentndrive.co.in" />
                        <link rel="canonical" href="https://rentndrive.co.in/"></link>
                    </Helmet>
            }

            <div>
                <Hero />
            </div>
            <div>
                <HowItWorks />
            </div>
            <div className='my-20'>
                <PopularCars
                    isLoading={isLoading}
                />
            </div>
            <div className='my-20'>
                <WhyChooseUs />
            </div>
            <div className='my-20'>
                <PageContent />
            </div>
            <div className='my-20'>
                <Faq />
            </div>
            <div className='mt-28'>
                <Testimonials />
            </div>
            <div className='mt-20'>
                <GetInTouch
                    isLoading={isLoading}
                />
            </div>
            <div className='my-10'>
                <FooterLinks />
            </div>
        </div>
    )
}

export default Home