import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import homeReducer from "./reducers/homeReducer";
import notificationReducer from "./reducers/notificationReducer";
import bookingReducer from "./reducers/bookingReducer";
import blogReducer from "./reducers/blogReducer";
import blogCategoryReducer from "./reducers/blogCategoryReducer";
import withdrawReducer from "./reducers/withdrawReducer";


const store = configureStore({
    reducer: {
        auth : authReducer , 
        home : homeReducer , 
        notification : notificationReducer ,
        booking : bookingReducer , 
        blog : blogReducer ,
        blogCategory : blogCategoryReducer ,
        withdraw : withdrawReducer ,
    },
});

export default store;