import BlogCard from 'components/blogs/BlogCard';
import Categories from 'components/blogs/Categories';
import Cover from 'components/blogs/Cover';
import Filter from 'components/blogs/Filter';
import Breadcrumbs from 'components/global/Breadcrumbs';
import Heading from 'components/global/Heading';
import ItemNotFound from 'components/global/ItemNotFound'
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';
import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/blogReducer';
import fetcher from 'utils/fetcher';

const crumbs = [
    { label: "Home", path: "/" },
    { label: "Blogs", path: "/blogs" },
];

const Blogs = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    const { docs : blogs , currentPage , pages , docsCount , category , keyword , sort } = useSelector(state => state.blog);

    const queryKey = ['fetch-all-blogs' , currentPage , category , keyword , sort ];
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/blog?page=${currentPage}&keyword=${keyword}&category=${category}&sort=${sort}`);
    } , { refetchOnWindowFocus : false });

    useEffect(() => {
        if(data) {
            const { data : { data : { page , pages , docs , docsCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docsCount));
        }
    } ,[data])


    return (
        <div>
            <Cover />
            <div className='container mx-auto sm:px-0 px-4 py-6'>
                <div className='md:w-[80%] sm:w-[90%] w-full mx-auto'>
                    <Breadcrumbs crumbs={crumbs} />
                    <div className="mt-8">
                        <Filter />
                        <div>
                            <Categories />
                        </div>
                        {
                            isLoading
                            ? 
                                <Loader />
                            :
                            blogs?.length > 0 
                            ?
                                <div className='mt-12 grid 2xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8'>
                                    {
                                        blogs?.map(item => (
                                            <BlogCard blog={item} key={item?._id}/>
                                        ))
                                    }
                                </div>
                            : 
                                <ItemNotFound message='No Blog found.' />
                        }
                        <div className='mt-10'>
                            <Pagination
                            currentPage={currentPage}
                            pageCount={pages}
                            setPage={setCurrentPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blogs;