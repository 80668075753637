import React from 'react'

const BecomeHostBtn = () => {
    return (
        <a
        className='btn-primary py-2 px-4 cursor-pointer'
        href='https://host.rentndrive.co.in'
        target='_blank'
        >
            Become a host
        </a>
    )
}

export default BecomeHostBtn